import React from 'react'
import './Footer.css'

const  Footer = () => {
  return (
    <div className='footer-container'>
    <footer>
        <p>Designed & Programmed by Rebecca Bell ©</p>
    </footer>
    </div>
  )
}

export default Footer;